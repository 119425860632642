import styled from "@emotion/styled";
import {
	Avatar,
	AvatarProps,
	Badge,
	BadgeProps,
	Fab,
	IconButton,
} from "@mui/material";
import LiveChat from "components/icons/LiveChat";
import { useAccount } from "contexts/AccountContext";
import { useCart } from "contexts/CartContext";
import { SettingsOptions } from "contexts/SettingContext";
import useDeviceSize from "hooks/useDeviceSize";
import useSettings from "hooks/useSettings";
import {
	useEffect,
	useState,
} from "react";
import { cdpClient } from "utils/analytics";
import { SITE_LOCALE } from "utils/config";
import {
	chatwootBaseUrl,
	chatwootWebsiteToken,
	getChatwootHashFromServer,
} from "utils/getChatwootKeysFromServer";

export const toggleChatwootLiveChat = ({
	updateSettings,
	settings,
}: {
	updateSettings: (
		settings: SettingsOptions
	) => void;
	settings: SettingsOptions;
}) => {
	cdpClient?.track(
		"Open Mobile Chatwoot Widget",
		{
			category: "Chatwoot",
			label: "Mobile Chatwoot Widget",
			eventType: "click",
		}
	);
	window?.$chatwoot?.toggle("open");
	updateSettings({
		...settings,
		has_clicked_on_live_chat: true,
	});
};

const StyledBadge = styled(Badge)(
	({ theme, variant }) =>
		variant === "standard"
			? {
					"& .MuiBadge-badge": {
						backgroundColor: (
							theme as any
						).palette.error.main,
						color: "error",
					},
			  }
			: {
					"& .MuiBadge-badge": {
						backgroundColor: "#44b700",
						color: "#44b700",
						boxShadow: `0 0 0 2px ${
							(theme as any).palette
								.background.paper
						}`,
						"&::after": {
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							borderRadius: "50%",
							animation:
								"ripple 1.2s infinite ease-in-out",
							border:
								"1px solid currentColor",
							content: '""',
						},
					},
					"@keyframes ripple": {
						"0%": {
							transform: "scale(.8)",
							opacity: 1,
						},
						"100%": {
							transform: "scale(2.4)",
							opacity: 0,
						},
					},
			  }
);

export const ChatWootIconAndBadge = ({
	badgeProps,
	avatarProps,
}: {
	badgeProps?: BadgeProps;
	avatarProps?: AvatarProps;
}) => {
	const {
		settings: {
			has_clicked_on_live_chat:
				hasClickedOnLiveChat,
		},
	} = useSettings();

	return (
		<StyledBadge
			{...badgeProps}
			badgeContent={
				hasClickedOnLiveChat
					? undefined
					: 1
			}
			overlap='circular'
			anchorOrigin={{
				...badgeProps?.anchorOrigin,
				vertical: "top",
				horizontal: "right",
			}}
			variant='standard'>
			<Avatar {...avatarProps}>
				<LiveChat />
			</Avatar>
		</StyledBadge>
	);
};

export const ChatWootCTAText = ({
	launcherTitle = "Chat instantanée",
}: {
	launcherTitle?: string;
}) => {
	const { upLg } = useDeviceSize();
	return upLg ? (
		<div>{launcherTitle}</div>
	) : null;
};

export const ChatWootBubbleButton =
	() => {
		const {
			settings,
			updateSettings,
			retrievingSettings,
		} = useSettings();

		if (retrievingSettings) return null;

		return (
			<IconButton
				aria-label='Live chat'
				title="Chat instantanée avec un l'équipe"
				onClick={() =>
					toggleChatwootLiveChat({
						settings,
						updateSettings,
					})
				}>
				<ChatWootIconAndBadge
					avatarProps={{
						sx: {
							backgroundColor:
								"transparent",
							color: "unset",
						},
					}}
				/>
			</IconButton>
		);
	};

export const ChatWootBubbleFAB = () => {
	const { upLg } = useDeviceSize();
	const {
		updateSettings,
		retrievingSettings,
		settings,
	} = useSettings();
	if (retrievingSettings) return null;
	return (
		<Fab
			aria-label='Live chat'
			title="Chat instantanée avec un l'équipe"
			size={upLg ? "large" : "medium"}
			variant={
				upLg ? "extended" : "circular"
			}
			sx={{
				position: "fixed",
				bottom: upLg ? 24 : 16,
				right: upLg ? 24 : 16,
				color: "white",
				backgroundColor: "black",
				"&:hover": {
					backgroundColor: "black",
				},
				// make text not uppercase
				textTransform: "none",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "row",
				columnGap: 1,
			}}
			onClick={() =>
				toggleChatwootLiveChat({
					updateSettings,
					settings,
				})
			}>
			<ChatWootIconAndBadge
				badgeProps={{
					variant: "standard",
				}}
				avatarProps={{
					sx: {
						backgroundColor:
							"transparent",
					},
				}}
			/>
			<ChatWootCTAText />
		</Fab>
	);
};

const VirtualChatwootWidget = ({
	variant = "default",
}: {
	variant?: "default" | "custom";
}) => {
	const { upLg } = useDeviceSize();
	const {
		customer,
		retrievingCustomer,
	} = useAccount();
	const { cart } = useCart();

	const { settings } = useSettings();

	const [hasLoaded, setHasLoaded] =
		useState<boolean>(false);

	useEffect(() => {
		if (
			!settings?.privacy_consent
				?.essential
		)
			return;

		// Add Chatwoot Settings
		window.chatwootSettings =
			variant === "default"
				? {
						hideMessageBubble: false,
						position: "right", // This can be left or right
						locale:
							SITE_LOCALE.toLowerCase(), // Language to be set
						useBrowserLanguage: true, // Set widget language from user's browser
						type: upLg
							? "expanded_bubble"
							: "standard", // [standard, expanded_bubble]
						darkMode: "light", // [light, auto]
						launcherTitle:
							"Chat instantanée",
				  }
				: {
						hideMessageBubble: true,
				  };

		// Paste the script from inbox settings except the <script> tag
		(function (d, t) {
			var BASE_URL = chatwootBaseUrl;
			var g = d.createElement(
					t
				) as HTMLScriptElement,
				s =
					d.getElementsByTagName(t)[0];
			g.src =
				BASE_URL + "/packs/js/sdk.js";
			g.defer = true;
			g.async = true;
			s?.parentNode?.insertBefore(g, s);
			g.onload = async function () {
				window.chatwootSDK.run({
					websiteToken:
						chatwootWebsiteToken,
					baseUrl: BASE_URL,
				});
			};
		})(document, "script");
	}, [
		customer?.id,
		retrievingCustomer,
		cart,
		variant,
		upLg,
		settings?.privacy_consent
			?.essential,
	]);

	useEffect(() => {
		window.addEventListener(
			"chatwoot:ready",
			async function () {
				setHasLoaded(true);
			}
		);
		return () => {
			window.removeEventListener(
				"chatwoot:ready",
				() => {}
			);
		};
	}, [
		customer?.id,
		retrievingCustomer,
		cart,
	]);

	useEffect(() => {
		if (!hasLoaded) return;

		const identifyUser = async () => {
			const userIdentifier =
				customer?.id ||
				cart?.customer_id ||
				cart?.id ||
				"";
			const identifierHash =
				await getChatwootHashFromServer(
					userIdentifier
				).then((hash) => {
					return hash;
				});

			window?.$chatwoot?.setUser(
				userIdentifier,
				{
					identifier_hash:
						identifierHash,
					email:
						customer?.email ||
						cart?.email ||
						"",
					name:
						customer?.first_name &&
						customer?.last_name
							? `${customer?.first_name} ${customer?.last_name}`
							: cart?.billing_address
									?.first_name &&
							  cart?.billing_address
									?.last_name
							? `${cart?.billing_address?.first_name} ${cart?.billing_address?.last_name}`
							: cart?.shipping_address
									?.first_name &&
							  cart?.shipping_address
									?.last_name
							? `${cart?.shipping_address?.first_name} ${cart?.shipping_address?.last_name}`
							: "Guest-" + cart?.id,
					phone_number:
						customer?.phone ||
						cart?.billing_address
							?.phone ||
						cart?.shipping_address
							?.phone ||
						"",
					avatar_url: "",
				}
			);
		};

		if (
			!retrievingCustomer &&
			cart?.id
		) {
			identifyUser();
		}
	}, [
		customer?.email,
		customer?.first_name,
		customer?.id,
		customer?.last_name,
		customer?.phone,
		cart?.billing_address?.first_name,
		cart?.billing_address?.last_name,
		cart?.billing_address?.phone,
		cart?.email,
		cart?.id,
		cart?.shipping_address?.first_name,
		cart?.shipping_address?.last_name,
		cart?.shipping_address?.phone,
		retrievingCustomer,
		cart?.customer_id,
		hasLoaded,
	]);

	if (
		!hasLoaded ||
		variant === "default"
	)
		return null;
	return <ChatWootBubbleFAB />;
};

export default VirtualChatwootWidget;
